import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { BsPerson } from "react-icons/bs";
import { CgProfile } from 'react-icons/cg';
import IGLogo from '../assets/infernoLogo.png';
import UpdatePassword from './UpdatePassword';
import Logout from './Logout';
import UpdatePaymentMethod from './UpdatePaymentMethod';

const NavbarMain = () => {
  const [nav, setNav] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <div className="navbar-container flex justify-between items-center h-14 w-screen mx-auto px-4 text-white">
      <div className="flex items-center">
        <img src={IGLogo} alt="Logo" className="h-10 w-10 mr-2" /> {/* Replace logo.png with the path to your logo */}
        <h1 className="text-3xl font-bold text-[#FB7A53]">
          InfernoGuard Risk-Assessment
        </h1>
      </div>
      <div className="relative">
        <CgProfile
          size={40}
          className="cursor-pointer"
          onClick={toggleSettings}
          color="#DE6D14"
        />
        {settingsOpen && (
          <div className="absolute right-0 top-full mt-2 w-64 bg-[#323f4c] rounded-lg shadow-xl p-4 space-y-4 text-center text-white border-white border-2">
            <h2 className="text-2xl font-bold">Profile Settings</h2>
            <div className="border-t border-b border-white py-2">
              <UpdatePassword/>
            </div>
            <div className="border-t border-b border-white py-2">
              <Logout/>
            </div>
          </div>
        )}
      </div>
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
    </div>
  );
};

export default NavbarMain;
