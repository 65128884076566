import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useUserAuth } from "../contexts/UserAuthContext";
import NavbarMain from "../components/NavbarMain";
import { Tooltip } from 'reactstrap';
import InfoIcon from '../assets/info_icon.png';
import Stepper from 'react-stepper-horizontal';

const Dashboard = ({ riskAssessmentInProgress }) => {
  const [step, setStep] = useState(0);
  const { logOut, user } = useUserAuth();
  const [assessments, setAssessments] = useState([]);
  const navigate = useNavigate();
  const [riskAssessments, setRiskAssessments] = useState([]);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const steps = [
    { title: 'Place Order', onClick: () => {} },
    { title: 'Assessment Generating', onClick: () => {} },
    { title: 'Assessment Available', onClick: () => {} },
  ];

  const stepContainerStyle = {textAlign: 'center', marginTop: '20px'};

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDocs(collection(db, 'assessments'));
      setAssessments(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));

      const token = localStorage.getItem('token');
      const response = await fetch('https://api.igbackend.com/assessment', {
        method: 'GET',
        headers : {
                'Authorization': `Bearer ${token}`,
        }
      });

      if(response.ok){
        const apiData = await response.json();
        setRiskAssessments(apiData);
      } else {
        console.error(`Error: ${response.status}`);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="dashboard" style={{ backgroundColor: '#323f4c', minHeight: '100vh' }}>
      <NavbarMain />
      <h1 className='md:text-4xl sm:text-6xl text-4xl font-bold md:py-6 text-white text-center'>Risk Assessments</h1>

      <div className='section'>
        <div className='heading' style={{ paddingLeft: '300px' }}>
          <h2 id='title1' className='text-2xl font-bold text-white'>Create a New Risk Assessment</h2>
          <img id="info1" src={InfoIcon} className='info-icon' style={{ width: '16px', height: '16px' }} />
          <Tooltip className="custom-tooltip" placement="right" isOpen={tooltipOpen1} target="info1" toggle={toggle1}>
            Provide your property information to receive a quote for a risk assessment
          </Tooltip>
        </div>
        <div className='box w-4/5 flex flex-col'>
          {/* Box content */}
          <div className='flex justify-between'>
            <div>
              <p className='font-medium'>Start a New Assessment</p>
              <p className='text-sm'>Press generate to begin the assessment and receive a quote</p>
            </div>
            <div className='button-container'>
              <Link to="/property-details" className='button-link'>
                <button className='button generate-button'>Generate</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='heading' style={{ paddingLeft: '300px' }}>
          <h2 id='title2' className='text-2xl font-bold text-white'>Assessments Currently Generating</h2>
          <img id="info2" src={InfoIcon} className='info-icon' style={{ width: '16px', height: '16px' }} />
          <Tooltip className="custom-tooltip" placement="right" isOpen={tooltipOpen2} target="info2" toggle={toggle2}>
            Assessments in progress will appear here
          </Tooltip>
        </div>
        <div className='box w-4/5 flex flex-col'>
          {/* Box content */}
          {riskAssessments.map((assessment, index) => {
            if (!assessment.assessmentWebappUrl) {
              return (
                <div key={index} className='justify-between'>
                  <div>
                    <p className='font-medium'>{assessment.address}</p>
                    <p className='text-sm'>Once all of the steps below have been completed, your assessment will appear in the Generated Assessments below.</p>
                  </div>
                  <div style={stepContainerStyle}>
                      <Stepper
                          steps={steps}
                          activeStep={1}
                          activeColor="#FB7A53"
                          completeColor="#FB7A53"
                          defaultColor="#C3CCD6"
                          size={30}
                          circleTop={20}
                          titleTop={20}
                          completeBarColor="#FB7A53"
                      />
                  </div>
                </div>
              )
            }
            return null;
          })}

          {riskAssessments.every(assessment => assessment.assessmentWebappUrl) && (
            <div className='flex justify-between'>
              <div>
                <p className='font-medium'>No current assessments being generated</p>
                <p className='text-sm'>View old assessments or generate a new one</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {riskAssessments.some(assessment => assessment.assessmentWebappUrl) && (
        <div className='section'>
          <div className='heading' style={{ paddingLeft: '300px' }}>
            <h2 id='title3' className='text-2xl font-bold text-white'>Generated Assessments</h2>
            <img id="info3" src={InfoIcon} className='info-icon' style={{ width: '16px', height: '16px' }} />
            <Tooltip
              placement="right"
              isOpen={tooltipOpen3}
              target="info3"
              toggle={toggle3}
              className="custom-tooltip"
            >
              Your assessments will appear here
            </Tooltip>
          </div>
          {riskAssessments.map((assessment, index) => {
            if (assessment.assessmentWebappUrl) {
              return (
                <div key={index} className='box w-4/5 flex flex-col'>
                  {/* Box content */}
                  <div className='flex justify-between'>
                    <div>
                      <p className='font-medium'>{assessment.address}</p>
                      <p className='text-sm'>Click view to access the risk assessment web app</p>
                    </div>
                    <div className='button-container'>
                      <a href={assessment.assessmentWebappUrl} target='_blank' rel='noreferrer' className='button'>View</a>
                    </div>
                  </div>
                </div>
              )
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
