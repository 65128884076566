import React, {useEffect, useRef, useState} from 'react';
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import Stepper from 'react-stepper-horizontal';
import axios from 'axios';
import Modal from 'react-modal';

const libraries = ['places'];
const mapContainerStyle = {width: '60%', height: '450px', borderRadius: '20px', margin: '0 auto'};
const center = {lat: 37.7749, lng: -122.4194};
const stepContainerStyle = {textAlign: 'center', marginTop: '20px'};
const inputStyle = {
    width: '40%',
    padding: '10px',
    borderRadius: '10px',
    fontSize: '16px',
    marginBottom: '10px',
    marginRight: '10px',
    border: '1px solid black'
};
const buttonStyle = {
    backgroundColor: "#FB7A53",
    color: "white",
    padding: '10px 20px',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 'bold'
};
const backButtonStyle = {...buttonStyle, position: 'absolute', left: '13%', top: '15%'};
const clearButtonStyle = {...buttonStyle, position: 'absolute', right: '13%', top: '15%'};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: '20px',
        width: '30%',
        height: '40%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const stepContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '18%',
};

Modal.setAppElement('#root');

const GoogleMapPage = () => {
    const [step, setStep] = useState(0);
    const [address, setAddress] = useState('');
    const [markers, setMarkers] = useState([]);
    const [boundaryType, setBoundaryType] = useState('');
    const [acreage, setAcreage] = useState('');
    const [acreageError, setAcreageError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [error, setError] = useState('');

    const mapRef = useRef(null);

    const isInteger = (value) => {
      return /^\d+$/.test(value);
    }

    const handleAcreageChange = (e) => {
      const value = e.target.value;
      setAcreage(value);
    };

    const handleNextStep = () => {
      if (isInteger(acreage)) {
          setAcreageError(null);
          setStep(3);
      } else {
          setAcreageError('Please enter a whole number');
      }
    };

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyCdoqm108EFFmulKUwJJ2SGDJnHbzsC6M8',
        libraries,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (acreage === '' || address === '' || (boundaryType === 'Manually input property boundaries' && markers.length === 0)) {
            setError('Missing information');
            return;
        }
        let data;
        if (boundaryType === 'Upload a file') {
            data = new FormData();
            data.append('acreage', acreage);
            data.append('address', address);
            data.append('file', selectedFile);
        } else {
            data = {
                'acreage': acreage,
                'address': address,
            }
            data['boundaries'] = markers.map(marker => [parseFloat(marker.lng), parseFloat(marker.lat)]);
        }
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
            }
            const response = await axios.post('https://api.igbackend.com/assessment', data, {headers});
            const {url} = response.data;
            setCheckoutUrl(url);
            window.open(url, '_blank');
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setError('');
    }, [acreage, address, boundaryType, markers]);

    const onMapClick = (event) => {
        if (boundaryType === 'Manually input property boundaries') {
            setMarkers((current) => [...current, {lat: event.latLng.lat(), lng: event.latLng.lng()}]);
        }
    };

    const clearMarkers = () => {
        setMarkers([]);
    };

    const moveMapToAddress = async () => {
        setStep(1);
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    address
                )}&key=AIzaSyCdoqm108EFFmulKUwJJ2SGDJnHbzsC6M8`
            );
            const {results} = response.data;
            if (results && results.length > 0) {
                const {geometry} = results[0];
                const {location} = geometry;
                setMarkers([{lat: location.lat, lng: location.lng}]);
                mapRef.current.panTo({lat: location.lat, lng: location.lng});
            } else {
                console.error('No results found for this address');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleBoundaryTypeChange = (event) => {
        setBoundaryType(event.target.value);
        if (event.target.value === 'Upload a file') {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    };

    const openUploadModal = () => {
        setShowModal(true);
    };

    const closeUploadModal = () => {
        setShowModal(false);
    };

    const handleFileChange = (e) => {
        setIsFileSelected(true);
        setSelectedFile(e.target.files[0]);
    };

    const handleMapLoad = (mapInstance) => {
        mapRef.current = mapInstance;
    };

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    const steps = [
        {title: 'ADDRESS'},
        {title: 'BOUNDARY POINTS'},
        {title: 'ACREAGE'},
        {title: 'CHECKOUT'}
    ];

    return (
        <div>
            <div style={stepContainerStyle}>
                <Stepper
                    steps={steps}
                    activeStep={step}
                    activeColor="#FF9675"
                    completeColor="#FF9675"
                    defaultColor="#C3CCD6"
                    size={30}
                    circleTop={0}
                    completeBarColor="#FF9675"
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                {isLoaded && (
                    <GoogleMap
                        id="map"
                        mapContainerStyle={mapContainerStyle}
                        zoom={8}
                        center={center}
                        onClick={onMapClick}
                        onLoad={handleMapLoad}
                    >
                        {markers.map((marker, i) => (
                            <Marker
                                key={`${marker.lat}-${marker.lng}-${i}`}
                                position={{lat: marker.lat, lng: marker.lng}}
                            />
                        ))}
                    </GoogleMap>
                )}
                {markers.length > 0 && (
                    <button onClick={clearMarkers} style={clearButtonStyle}>Clear</button>
                )}
            </div>
            <div style={{...stepContainerStyle, textAlign: 'left', marginLeft: '5%'}}>
                {step === 0 && (
                    <div style={stepContentStyle}>
                        <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Enter Your Primary Property Address</h2>
                        <h2 style={{paddingBottom: '10px'}}>Type your full address bellow</h2>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="address"
                            style={{...inputStyle, marginLeft: '0'}}
                        />
                        <button onClick={moveMapToAddress} style={buttonStyle}>Submit</button>
                    </div>
                )}
                {step === 1 && (
                    <div style={stepContentStyle}>
                        <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Set the Property Boundaries</h2>
                        <select onChange={handleBoundaryTypeChange} style={{...inputStyle, marginLeft: '0'}}>
                            <option>Select coordinate upload method</option>
                            <option>Upload a file</option>
                            <option>Manually input property boundaries</option>
                        </select>
                        {boundaryType === 'Manually input property boundaries' && (
                          <h2 style={{paddingBottom: '10px'}}>Click on the map to set your boundary points</h2>
                        )}
                        <button onClick={() => setStep(2)} style={buttonStyle}>Next</button>
                        <button onClick={() => setStep(0)} style={backButtonStyle}>Back</button>
                    </div>
                )}
                {step === 2 && (
                    <div style={stepContentStyle}>
                        <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Enter the Total Acreage of Your Property</h2>
                        <input
                            type="text"
                            value={acreage}
                            onChange={handleAcreageChange}
                            placeholder="acreage"
                            style={{...inputStyle, marginLeft: '0'}}
                        />
                        {acreageError && <p style={{color: 'red', paddingBottom: '10px'}}>{acreageError}</p>}
                        <button onClick={handleNextStep} style={buttonStyle}>Next</button>
                        <button onClick={() => setStep(1)} style={backButtonStyle}>Back</button>
                    </div>
                )}
                {step === 3 && (
                    <div style={stepContentStyle}>
                        <h2 style={{fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px'}}>Review and Checkout</h2>
                        <button style={buttonStyle} onClick={handleSubmit}>Checkout</button>
                        <button onClick={() => setStep(2)} style={backButtonStyle}>Back</button>
                        {error && <p>{error}</p>}
                    </div>
                )}
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={closeUploadModal}
                style={modalStyles}
            >
                <h2 style={{fontSize: '20px', fontWeight: 'bold', paddingBottom: '20px'}}>Upload a File</h2>
                <input type="file" onChange={handleFileChange}/>
                <button onClick={handleFileChange} style={{...buttonStyle, marginTop: '20px'}}>
                    {isFileSelected ? 'Upload' : 'Continue'}
                </button>
            </Modal>
        </div>
    );
};

export default GoogleMapPage;
