import React from 'react';

function ErrorPage() {
    return (
        <div>
            Error! Page not found
        </div>
    )
}

export default ErrorPage;