import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from './pages/HomePage';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import Dashboard from './pages/Dashboard';
import PropertyDetails from './pages/PropertyDetails';
import { UserAuthContextProvider } from "./contexts/UserAuthContext";

function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <Routes>
          {/* <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/update-profile" component={UpdateProfile} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/property-details" element={<PropertyDetails />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
          <Route path="*" element={<ErrorPage />}/>
        </Routes>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;