import Footer from "../components/Footer";
import Analytics from "../components/Analytics";
import Cards from "../components/Cards";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Hero from "../components/Hero";

const HomePage = () => {
  return (
    <div>
      <Hero />
    </div>
  )
};

export default HomePage;