import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { updatePassword as firebaseUpdatePassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCdoqm108EFFmulKUwJJ2SGDJnHbzsC6M8",
    authDomain: "riskassessment-webapp.firebaseapp.com",
    projectId: "riskassessment-webapp",
    storageBucket: "riskassessment-webapp.appspot.com",
    messagingSenderId: "432942659620",
    appId: "1:432942659620:web:c0166409b795f9f45240b1",
    measurementId: "G-F6W3R3P2NK"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const addMarkerToFirebase = async (markerData) => {
  const db = getFirestore(app);
  await addDoc(collection(db, 'markers'), markerData);
};
export const updatePassword = async (newPassword) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  await firebaseUpdatePassword(user, newPassword);
};