import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post('https://api.igbackend.com/auth/login', { email, password });
      console.log(response.data)
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate("/dashboard");
      } else {
        throw new Error('Invalid login credentials');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#323f4c' 
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '400px', 
        height: '500px', 
        padding: '50px', 
        borderRadius: '15px', 
        backgroundColor: 'white'
      }}>
        <h1 style={{ fontWeight: 'bold', marginBottom: '20px', fontSize: '32px' }}>Login</h1>
        <p style={{ marginBottom: '40px' }}>Enter the information below</p>
        <input 
          style={{ 
            width: '100%', 
            padding: '10px', 
            marginBottom: '20px', 
            borderRadius: '5px',
            borderColor: '#000000',
            borderWidth: '2px',
            borderStyle: 'solid'
          }} 
          type="email" 
          placeholder="Email" 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <input 
          style={{ 
            width: '100%', 
            padding: '10px', 
            marginBottom: '40px', 
            borderRadius: '5px',
            borderColor: '#000000',
            borderWidth: '2px',
            borderStyle: 'solid'
          }} 
          type="password" 
          placeholder="Password" 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button 
          style={{ 
            width: '100%', 
            padding: '10px', 
            marginBottom: '20px', 
            borderRadius: '5px', 
            backgroundColor: '#FB7A53', 
            color: 'white', 
            fontWeight: 'bold', 
            border: 'none' 
          }} 
          onClick={handleSubmit}
        >
          Login
        </button>
        <Link to="/register" style={{width: '100%'}}>
          <button 
            style={{ 
              width: '100%', 
              padding: '10px', 
              borderRadius: '5px', 
              backgroundColor: '#A9A9A9', 
              color: 'white', 
              fontWeight: 'bold', 
              border: 'none' 
            }}
          >
            Sign Up
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
